<template>
    <div class="login-body">
        <div class="login-panel"></div>

        <div class="login-content">
            <img src="images/logo-1.png" alt="babylon-layout" v-if="false" />
            <img src="layout/images/logo-black.png" alt="babylon-layout" />

            <h1><span>sign in </span>FLOWPAY</h1>

            <div class="login-input-wrapper">
                <InputText placeholder="Username" v-model="username" />
                <i class="pi pi-user"></i>
                <small v-if="v$.username.$error" class="p-error">{{ v$.username.$errors[0].$message }}</small>
            </div>
            <div class="login-input-wrapper">
                <Password v-model="password" placeholder="Password" toggleMask :feedback="false" style="width: 100%" @keyup.enter="handleLogin" />
                <small v-if="v$.password.$error" class="p-error">{{ v$.password.$errors[0].$message }}</small>
            </div>
            <div class="login-input-wrapper" style="color: red">{{ message }}</div>

            <Button icon="pi pi-sign-in" label="Sign In" @click="handleLogin" />
            <!-- <h1>{{ $t("title") }}</h1> -->
        </div>
    </div>
</template>

<script>
import { computed, reactive, ref, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/store';
import useValidate from '@vuelidate/core';
import { helpers, minLength, required } from '@vuelidate/validators';

export default {
    name: 'Login',
    setup() {
        const submitted = ref(false);
        const state = reactive({
            username: '',
            password: '',
            message: '',
            loading: false,
        });

        const store = useAuthStore();
        const router = useRouter();

        const rules = computed(() => {
            return {
                username: { required: helpers.withMessage('This field cannot be empty', required) },
                password: { required: helpers.withMessage('This field cannot be empty', required), minLength: helpers.withMessage('This field length >= 6', minLength(6)) },
            };
        });

        const v$ = useValidate(rules, state);

        const handleLogin = async () => {
            submitted.value = true;
            try {
                v$.value.$validate();
                if (!v$.value.$error) {
                    state.loading = true;
                    await store.login(state.username, state.password);
                    router.replace('/');
                }
            } catch (error) {
                state.message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                //console.log(JSON.stringify(error));
            } finally {
                state.loading = false;
                submitted.value = false;
            }
        };

        const goDashboard = () => {
            this.$router.push({ path: '/' });
        };

        return { ...toRefs(state), v$, handleLogin, goDashboard };
    },
};
</script>
